import { CartItemDetails } from '@customTypes/cart-items';
import { CheckoutInfo, CheckoutItem } from '@customTypes/checkout-info';
import HotelDetails from '@customTypes/hotel-details';
import User from '@customTypes/user';
import { NextAvailableHotelItem } from '@customTypes/next-available-hotels';
import Product from '@customTypes/product';
import { ProductDetails } from '@customTypes/product-details';
import { AddonItem } from '@customTypes/booking-details';

import { getDateWithDashes, getDateWithSlashes } from '@helpers/date';
import SHA256 from '@helpers/encoders';
import { calculateTotalPrice, isAnyProductAvailable } from '@helpers/product';

import { flatten } from 'lodash';

import { sendToGTM } from './GTM';

const addonSelected = (addon: AddonItem, hotel: HotelDetails) => {
  sendToGTM({
    event: 'dlp_addon_selected',
    dlp: {
      addon_selected: {
        item_id: addon.addon_id,
        item_name: addon.name,
        affiliation: hotel.name,
        coupon: '',
        currency: 'USD',
        discount: 0,
        index: 0,
        item_brand: hotel.name,
        item_category: 'addon',
        item_list_id: hotel.metadata.hotel_id,
        item_list_name: hotel.name,
        item_variant: '',
        location_id: '',
        price: addon.price,
        quantity: addon.units,
      },
    },
  });
};

const addToCart = async (
  product: Product,
  hotelDetails: HotelDetails,
  addOnItems: any,
  adultsCount: number,
  childrenCount: number,
  cart: CartItemDetails,
  date: Date,
) => {
  const items = [
    {
      item_id: product.product_id,
      item_name: product.name,
      affiliation: hotelDetails.name,
      coupon: '',
      currency: 'USD',
      discount: 0,
      index: 0,
      item_brand: hotelDetails.name,
      item_category: product.product_type,
      item_category2: 'adult',
      item_list_id: hotelDetails.metadata.hotel_id,
      item_list_name: hotelDetails.name,
      item_variant: '',
      location_id: '',
      price: product.adult_price,
      quantity: adultsCount,
    },
  ];

  // include children in the items array
  if (childrenCount > 0) {
    items.push({
      item_id: product.product_id,
      item_name: product.name,
      affiliation: hotelDetails.name,
      coupon: '',
      currency: 'USD',
      discount: 0,
      index: 0,
      item_brand: hotelDetails.name,
      item_category: product.product_type,
      item_category2: 'child',
      item_list_id: hotelDetails.metadata.hotel_id,
      item_list_name: hotelDetails.name,
      item_variant: '',
      location_id: '',
      price: product.children_price,
      quantity: childrenCount,
    });
  }

  // Include addons in the items array
  if (addOnItems && addOnItems.length > 0) {
    addOnItems.forEach((addon: any) => {
      if (addon.isChecked) {
        items.push({
          item_id: addon.addon_id,
          item_name: addon.name,
          affiliation: hotelDetails.name,
          coupon: '',
          currency: 'USD',
          discount: 0,
          index: 0,
          item_brand: hotelDetails.name,
          item_category: 'addon',
          item_category2: addon.addon_type,
          item_list_id: hotelDetails.metadata.hotel_id,
          item_list_name: hotelDetails.name,
          item_variant: '',
          location_id: '',
          price: addon.price,
          quantity: addon.units,
        });
      }
    });
  }

  const eventId = `${cart.id}_${cart.update_time}`;

  sendToGTM({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'USD',
      value: calculateTotalPrice(product, adultsCount, childrenCount, addOnItems),
      items,
    },
    tiktok_event_id: eventId,
    facebook_add_to_cart: {
      brand: hotelDetails.name,
      content_category: product.product_type,
      content_ids: [product.product_id],
      content_name: product.name,
      content_type: 'product',
      currency: 'USD',
      id: eventId,
      value: calculateTotalPrice(product, adultsCount, childrenCount, addOnItems),
    },
    klaviyo_add_to_cart: {
      Categories: product.product_type,
      CheckoutURL: `${window.location.origin}/checkout-confirmation`,
      ImageURL: hotelDetails.image[0].picture.details.url,
      Metadata: {
        Brand: hotelDetails.name,
        Children: childrenCount,
        ChildrenPrice: product.children_price,
        TotalChildren: +product.children_price * (childrenCount || 0),
        Adults: adultsCount,
        AdultPrice: product.adult_price,
        TotalAdult: +product.adult_price * (adultsCount || 0),
        Total: calculateTotalPrice(product, adultsCount, childrenCount, addOnItems),
      },
      ProductID: product.product_id,
      ProductName: product.name,
      URL: window.location.href,
    },
  });

  if (window.Iterable) {
    await window.Iterable.addedToCart(
      addOnItems,
      adultsCount,
      childrenCount,
      date,
      hotelDetails,
      product,
    );
  }
};

const bookNowClicked = (checkoutInfo: CheckoutInfo | undefined) => {
  if (!checkoutInfo) return;

  const payload = {
    currency: 'USD',
    value: checkoutInfo.total,
    items: checkoutInfo.extracted_items.map((item: CheckoutItem) => ({
      item_id: item.product_id,
      item_name: item.name,
      affiliation: checkoutInfo.hotel_name,
      coupon: checkoutInfo.coupon_code,
      currency: 'USD',
      discount: 0,
      index: 0,
      item_brand: checkoutInfo.hotel_name,
      item_category: item.product_type || item.product_type_tag, // day_pass | cabana..
      item_category2: item.type, // adult | child
      item_list_id: checkoutInfo.hotel_id,
      item_list_name: checkoutInfo.hotel_name,
      item_variant: '',
      location_id: '',
      price: item.price,
      quantity: item.units,
    })),
  };

  // Extract addons from items.
  const addons = flatten(
    checkoutInfo.extracted_items
      .map((addon) => addon.addon_items) // get all addons from the product
      .filter((e) => e.length), // remove empty addons
  );

  // Include addons as items in the payload.
  addons.forEach((addon: AddonItem) => {
    payload.items.push({
      item_id: addon.addon_id,
      item_name: addon.name,
      affiliation: checkoutInfo.hotel_name,
      coupon: checkoutInfo.coupon_code,
      currency: 'USD',
      discount: 0,
      index: 0,
      item_brand: checkoutInfo.hotel_name,
      item_category: 'addon',
      item_category2: addon.type,
      item_list_id: checkoutInfo.hotel_id,
      item_list_name: checkoutInfo.hotel_name,
      item_variant: '',
      location_id: '',
      price: addon.price,
      quantity: addon.units,
    });
  });

  sendToGTM({
    event: 'dlp_book_now_to_checkout_clicked',
    dlp: {
      book_now: payload,
    },
  });
};

const bookNowScrollToProductsFixedBar = (hotel: HotelDetails) => {
  sendToGTM({
    event: 'dlp_footer_book_now_clicked',
    dlp: {
      hotel_name: hotel.name,
    },
  });
};

const dateSelectedFromFixedBar = (date: Date) => {
  sendToGTM({
    event: 'dlp_footer_date_entered',
    selected_date: date ? getDateWithSlashes(date) : null,
  });
};

const dlpPageView = async (
  hotel: HotelDetails,
  productDetails: ProductDetails,
  selected_date: string,
  user: User,
  sessionID: string,
) => {
  const email = user?.email ? await SHA256(user?.email.toLowerCase().trim()) : null;

  sendToGTM({
    event: 'dlp_page_viewed',
    selected_date,
    dlp: {
      availability: +isAnyProductAvailable(productDetails.products),
      hotel_city: hotel.city_name,
      hotel_name: hotel.name,
      hotel_slug: hotel.url,
    },
    sojern_dlp_page_viewed: {
      vd1: selected_date ? getDateWithDashes(new Date(selected_date)) : null,
      vd2: selected_date ? getDateWithDashes(new Date(selected_date)) : null,
      vf1: hotel.city_name,
      vs1: hotel.code,
      vn1: hotel.country_code,
      t: null,
      sha256_eml: email,
      ccid: user ? user.id?.toString() : sessionID,
    },
  });

  if (window.Iterable) {
    await window.Iterable.hotelViewed(hotel);
  }
};

const dlpSmartCalendarOpen = () => {
  sendToGTM({
    event: 'dlp_smart_calendar_open',
  });
};

const dlpSmartCalendarProductFilterApplied = (
  hotelId: number | null,
  productType: string | undefined,
): void => {
  sendToGTM({
    event: 'dlp_smart_calendar_product_filter_applied',
    dlp: {
      hotel_id: hotelId,
      smart_calendar_filter: productType,
    },
  });
};

const getReadyTabClicked = (hotel: HotelDetails) => {
  sendToGTM({
    event: 'menu_get_ready_clicked',
    dlp: {
      hotel_name: hotel.name,
    },
  });
};

const loadMoreReviewsClick = (hotel: HotelDetails) => {
  sendToGTM({
    event: 'load_more_reviews_clicked',
    dlp: {
      hotel_name: hotel.name,
    },
  });
};

const noInventory = (product: Product, hotel: HotelDetails, date: Date | undefined) => {
  sendToGTM({
    event: 'dlp_no_inventory',
    selected_date: date ? getDateWithSlashes(date) : null,
    dlp: {
      no_inventory: {
        product_id: product.product_id,
        product: product.name,
        hotel: hotel.name,
        hotel_id: hotel.metadata.hotel_id,
        city: hotel.city_name,
      },
    },
  });
};

const photoSwipeDLP = (hotelName: string | null) => {
  sendToGTM({
    event: 'dlp_photos_swiped',
    dlp: {
      hotel_name: hotelName,
    },
  });
};

const productCategoryClicked = (category: string) => {
  sendToGTM({
    event: 'dlp_product_category_clicked',
    dlp: {
      category,
    },
  });
};

const productClicked = (product: Product, hotel: HotelDetails) => {
  sendToGTM({
    event: 'dlp_product_clicked',
    product: {
      product_name: product.name,
      product_id: product.product_id,
    },
    dlp: {
      hotel_name: hotel.name,
    },
  });
};

const productQuantitySelected = (product: Product, hotel: HotelDetails) => {
  sendToGTM({
    event: 'dlp_product_quantity_selected',
    product: {
      product_name: product.name,
      product_id: product.product_id,
    },
    dlp: {
      hotel_name: hotel.name,
    },
  });
};

const productsTabClicked = (hotel: HotelDetails) => {
  sendToGTM({
    event: 'menu_products_clicked',
    dlp: {
      hotel_name: hotel.name,
    },
  });
};

const readReviewsClick = (hotel: HotelDetails) => {
  sendToGTM({
    event: 'read_reviews_clicked',
    dlp: {
      hotel_name: hotel.name,
    },
  });
};

const reviewsTabClicked = (hotel: HotelDetails) => {
  sendToGTM({
    event: 'menu_reviews_clicked',
    dlp: {
      hotel_name: hotel.name,
    },
  });
};

const selectADateClickOnFixedBar = (hotel: HotelDetails) => {
  sendToGTM({
    event: 'dlp_footer_select_a_date_clicked',
    dlp: {
      hotel_name: hotel.name,
    },
  });
};

const selectADateFirstError = () => {
  sendToGTM({
    event: 'select_a_date_first_error',
  });
};

const showAllAmenitiesClicked = (hotel: HotelDetails) => {
  sendToGTM({
    event: 'dlp_show_all_amenities_clicked',
    dlp: {
      hotel_name: hotel.name,
    },
  });
};

const viewItemList = (hotel: HotelDetails, products: Product[]) => {
  // Only used by UA, remove when fully migrated to GA4.
  sendToGTM({
    event: 'viewed_product', // Only used by UA
  });

  sendToGTM({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: hotel.id,
      item_list_name: hotel.name,
      items: products.map((product: Product) => ({
        item_id: product.product_id,
        item_name: product.name,
        affiliation: hotel.name,
        coupon: '',
        currency: 'USD',
        discount: 0,
        index: 0,
        item_brand: hotel.name,
        item_category: product.product_type,
        item_list_id: product.product_id,
        item_list_name: hotel.name,
        item_variant: '',
        location_id: '',
        price: product.adult_price,
        quantity: 0,
      })),
    },
    klaviyo_viewed_product: products.map((product) => ({
      ProductName: product.name,
      ProductID: product.product_id,
      Categories: product.product_type,
      ImageURL: hotel.image[0].picture.details.url,
      URL: window.location.href,
      Brand: hotel.name,
      Price: product.adult_price,
    })),
  });
};

// Feature on hold.
const dlpExploreMoreHotelsClick = (data: any): void => {
  sendToGTM({
    event: 'dlp_explore_more_hotels_clicked',
    dlp: {
      availability: +isAnyProductAvailable(data.current_hotel.products),
      explore_more_card_position: data.key + 1,
      explore_more_hotel_city: data.current_hotel.city_name,
      explore_more_target_hotel_slug: data.target_hotel.url,
      explore_more_hotel_name: data.current_hotel.name,
      explore_more_hotel_slug: data.current_hotel.url,
    },
    selected_date: data.smart_calendar_date || null,
  });
};

const dlpShowNextAvailableClicked = (
  product: Product,
  hotel: HotelDetails,
  date: Date | undefined,
) => {
  sendToGTM({
    event: 'show_next_available_clicked',
    dlp: {
      product_id: product.product_id,
      hotel_name: hotel.name,
    },
    selected_date: date ? getDateWithSlashes(date) : null,
  });
};

const dlpNextAvailableDateClicked = (product: Product, hotel: HotelDetails, newDate: Date) => {
  sendToGTM({
    event: 'show_next_available_date_clicked',
    dlp: {
      product_id: product.product_id,
      hotel_name: hotel.name,
    },
    selected_date: getDateWithSlashes(newDate),
  });
};

const dlpNearbyHotelClicked = (
  product: Product,
  hotel: NextAvailableHotelItem,
  date: Date | undefined,
) => {
  sendToGTM({
    event: 'show_next_nearby_hotel_clicked',
    dlp: {
      product_id: product.product_id,
      hotel_name: hotel.hotel_name,
    },
    selected_date: date ? getDateWithSlashes(date) : null,
  });
};

const dlpNoNextAvailableInventory = (
  product: Product,
  hotel: HotelDetails,
  date: Date | undefined,
) => {
  sendToGTM({
    event: 'show_next_available_no_inventory',
    dlp: {
      product_id: product.product_id,
      hotel_name: hotel.name,
    },
    selected_date: date ? getDateWithSlashes(date) : null,
  });
};

const dlpNoNextAvailableNearByHotels = (
  product: Product,
  hotel: HotelDetails,
  date: Date | undefined,
) => {
  sendToGTM({
    event: 'show_next_available_no_hotels_nearby',
    dlp: {
      product_id: product.product_id,
      hotel_name: hotel.name,
    },
    selected_date: date ? getDateWithSlashes(date) : null,
  });
};

export {
  addonSelected,
  addToCart,
  bookNowClicked,
  bookNowScrollToProductsFixedBar,
  dateSelectedFromFixedBar,
  dlpExploreMoreHotelsClick,
  dlpPageView,
  dlpSmartCalendarOpen,
  dlpSmartCalendarProductFilterApplied,
  getReadyTabClicked,
  loadMoreReviewsClick,
  noInventory,
  photoSwipeDLP,
  productCategoryClicked,
  productClicked,
  productQuantitySelected,
  productsTabClicked,
  readReviewsClick,
  reviewsTabClicked,
  selectADateClickOnFixedBar,
  selectADateFirstError,
  showAllAmenitiesClicked,
  viewItemList,
  dlpShowNextAvailableClicked,
  dlpNextAvailableDateClicked,
  dlpNearbyHotelClicked,
  dlpNoNextAvailableInventory,
  dlpNoNextAvailableNearByHotels,
};
